<template>
    <div>
        <a v-if="row.item.invoice_url"
           @click.prevent="$root.openDocument(row.item.invoice_name)"
           :href="$root.getDocumentUrl(row.item.invoice_name)"
        >{{ row.item.invoice_name }}</a>
        <div v-else>
            {{ row.item.invoice_name }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>